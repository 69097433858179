export const main = {
  Name: 'main',
  Landing: {},
  Quiz: {
    quizFlow: [
      {
        question: 'What is your age?',
        questionCode: 'age',
        subtitle: 'This information will be used to calculate your ED score.',
        answers: [
          '18 - 24 years',
          '25 - 34 years',
          '35 - 44 years',
          '45 - 54 years',
          '55+ years',
        ],
        type: 'question',
      },
      {
        question: "What's your relationship status?",
        questionCode: 'relationship_status',
        answers: [
          'Single and dating',
          'Single and not dating',
          'New relationship',
          'Long-term relationship',
          'Prefer not to say',
        ],
        type: 'question',
      },
      {
        question: "What's your sexual orientation?",
        questionCode: 'sexual_orientation',
        answers: [
          'Straight',
          'Gay',
          'Bisexual',
          'Pansexual',
          'Still figuring it out',
          'Prefer not to say',
        ],
        type: 'question',
      },
      {
        type: 'info',
        heading: 'Next we’ll think about the root cause of your issue',
        description:
          'Before getting to know you, we need to make sure you’re in the right place. So let’s check a few things about your general health.\n\nDon’t worry — if something comes up that we can’t help with, we’ll let you know.',
        button: 'Check the cause',
      },
      {
        question: 'Do you have any chronic medical conditions that we should know about?',
        subtitle: 'Choose as many as you like',

        questionCode: 'conditions',
        answers: [
          'High blood pressure',
          'High cholesterol',
          'Diabetes',
          'Heart problems',
          'Depression',
          'No, I don’t have any',
        ],
        type: 'question',
        isMultiSelect: true,
      },

      {
        question: 'How have you been feeling this past month?',
        questionCode: 'feelings',
        answers: [
          '😔 Burned out, exhausted, lethargic',
          '🙂 Overall I’m good, but sometimes I feel a bit down',
          '🙃 I’m okay but I wish I had more energy',
          '😊 I’m in a good place',
          '😄 Loving life and full of energy!',
        ],
        type: 'question',
      },
      {
        question: 'How do you feel about your performance?',
        questionCode: 'performance',
        answers: [
          '🥰 Totally happy',
          '🍾 I finish too quickly',
          '😕 I don’t get hard',
          '🤷‍♂️ I don’t stay hard',
          '🥵 I get tired quickly',
          '🧩 Something else',
        ],
        type: 'question',
      },
      {
        question: 'What are your healthy habits?',
        questionCode: 'habits',
        answers: [
          '🤔 I don’t have any',
          '😬 I try to take care of myself but don’t always have the time',
          '😌 I have my set routine and I stick to it',
          '😄 I’m always searching for something new and interesting to improve my wellbeing',
        ],
        type: 'question',
      },

      {
        type: 'info',
        heading: 'Whatever your stance on porn, Libiro can help',
        description:
          'We know there’s much more to erection issues than porn use.\n\nBut you’ll notice there’s one course about porn in your program.\n\nThis will give you a full perspective, which will optimize your chances of beating your issue.',
        button: 'Continue',
      },

      {
        question: 'Are there any sexual issues that you would like to work on?',
        questionCode: 'issues',
        answers: [
          "👍 No, I'm doing fine",
          '✋🏻 I don’t have much sexual desire',
          '💪🏻 I have issues keeping or getting an erection',
          '👐🏻 My sexual performance is either too fast or too slow',
          '👉🏻 I have trouble reaching orgasm',
        ],
        type: 'question',
      },

      {
        question:
          'And lastly... Have you heard of mindfulness techniques? Have you practiced any before?',
        questionCode: 'mindfulness',
        answers: [
          '👉🏻 What are mindfulness techniques?',
          '👉🏻 I’ve tried but I didn’t understand them',
          '👉🏻 I do them all the time, but I would like to improve',
        ],
        type: 'question',
      },

      {
        type: 'info',
        heading: 'Ready to see your plan?',
        description:
          "Libiro's approach to addressing erectile dysfunction is founded on the latest research in sexual health and thoughtfully developed by a team of experienced psychologists, coaches, and specialists.\n\nOur individualized strategy focuses on addressing the root causes of the issue, facilitating lasting and sustainable improvement.",
        button: 'Continue',
      },
      {
        question:
          'What is the amount of time you are willing to commit to resolving ED on a daily basis?',
        questionCode: 'amount_of_daily_time',
        answers: ['5 min / day', '10 min / day', '15 min / day', '20+ min / day'],
        type: 'question',
      },

      {
        type: 'email',
      },
    ],
  },
  GetStarted: {
    description:
      'We have created a personalized plan specific to you for stronger, long lasting erections and improved sexual well-being.',

    button: 'Continue',
  },
  Summary: {
    heading: 'Overcome erectile issues and take control of your sex life',
    resultsTitle: 'Inner critic score',
    suggestedPlan: 'Self-esteem improvement',
    issue: 'low self-esteem',
    summary: [
      {
        heading: 'Rejection anxiety',
        description:
          'You may be limiting yourself and holding yourself back because of a fear of being rejected.',
      },
      {
        heading: 'Overwhelming self-doubt',
        description:
          'Based on your scores, it seems that you have low self-confidence in yourself and your abilities.',
      },
      {
        heading: 'Perfectionism',
        description:
          'It appears that you might be a perfectionist and are never quite satisfied with your work.',
      },
      {
        heading: 'Powerful inner critic',
        description:
          'You frequently criticize yourself harshly for any deviations from your plans.',
      },
    ],
  },
  Purchase: {
    suggestedPlan: 'Self-Improvement',
    promise: 'boost self-esteem',
    subtitle:
      'Fast-track your workflow, boost your sales, streamline your operations and and stay ahead of the curve.',
    benefits: [
      {
        heading: '24/7 AI-powered employees',
        description:
          'Unlock the power of our advanced machine learning algorithms to access 24/7 available AI-based employees, ready to tackle any task and provide expert guidance.',
      },
      {
        heading: 'Leave prompting behind',
        description:
          'Our system is constantly monitored to ensure that each employee excels at their tasks, eliminating the need for manual prompting. Say goodbye to time-consuming and ineffective prompting techniques.',
      },
      {
        heading: 'Effortless problem solving',
        description:
          'Solve your business problems quickly and cost-effectively. Save hours of research and engineering by simply asking your AI Expert to provide solutions tailored to your specific needs.',
      },
      {
        heading: 'Instantly recruit expertise',
        description:
          'Transform our Experts and Tools into valuable members of your own business. By entering your business description into a business profile, you can seamlessly integrate our resources into your operations.',
      },
      {
        heading: 'Accelerated growth and productivity',
        description:
          'Experience accelerated growth and productivity by leveraging the expertise and automation provided by our AI-powered employees. Streamline your operations and achieve remarkable results in less time.',
      },
      {
        heading: 'Stay ahead of the competition',
        description:
          'Gain a competitive edge by harnessing the power of Libiro. Stay up-to-date with the latest AI advancements and leverage them to outperform your competitors in marketing, customer engagement, decision-making, and more.',
      },
    ],

    chart: {
      start: 'Your Insecurity Level',
      end: 'Your Target Level',
    },
    newspaper:
      'Transform your life and reach your goals with a customized plan to eliminate self-doubt and improve your relationships, social life, and career.\n \nTake the first step now towards becoming your best self.',
    scienceBacked: [
      {
        image: '/static/icons/cash.png',
        heading: 'Save time and money',
        description:
          'Streamline your workflow and optimize your resources. Our efficient tools and automated processes will save you valuable time and money, allowing you to focus on what truly matters for your business growth.',
      },
      {
        image: '/static/icons/relieved.png',
        heading: 'Increase conversion rate',
        description:
          'Experience a significant increase in your conversion rate. Our proven strategies and techniques will optimize your marketing efforts, resulting in higher conversion rates and greater business success.',
      },
      {
        image: '/static/icons/bed.png',
        heading: 'Boosted email revenue',
        description:
          'Unlock the potential of your email marketing campaigns. Our AI-based insights and personalized recommendations will help you craft compelling emails that engage your audience and drive revenue growth.',
      },
      {
        image: '/static/icons/brain.png',
        heading: 'Ad concepts and angles',
        description:
          'Stand out from the competition. Our AI-powered platform generates innovative ad concepts and angles that capture attention, drive engagement, and ultimately boost your advertising effectiveness.',
      },
    ],
  },
};
